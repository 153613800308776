import React from 'react';
import { Link } from 'gatsby';
import { useSelector } from 'react-redux';
import { Row, Col } from 'reactstrap';
import Layout from '../components/layouts/Layout';
import NewPropertyForm from '../components/newPropertyForm/NewPropertyForm';
import image from '../images/new-building.png';
import { selectIsLoggedIn, selectUserData } from '@a1-ict/core/authSlice';
import { useDialog } from '@a1-ict/core/dialogSlice/useDialog';

const AddProperty = () => {
  const userData = useSelector(selectUserData);
  const { open } = useDialog('login');
  const isLoggedIn = useSelector(selectIsLoggedIn);
  return (
    <Layout navColor="white" margined={true}>
      {userData && userData.is_business ? (
        <NewPropertyForm isBuilding={true}></NewPropertyForm>
      ) : (
        <Row
          style={{ height: '450px', paddingTop: '2rem' }}
          className="text-center justify-content-center"
        >
          <Col xs={12} sm={10} md={8}>
            <h4 className="mb-3">
              Обяви от тип Нови сгради на сайта на Имот.com могат да публикуват само потребители,
              които имат регистриран профил като Юридическо лице.
            </h4>
            <div className="mb-3">
              <img src={image} alt="" />
            </div>
            {!isLoggedIn ? (
              <button className="btn animated" onClick={open}>
                <span>Вход/Регистрация</span>
              </button>
            ) : (
              <Link to="/">
                <button className="btn animated">
                  <span>Начало</span>
                </button>
              </Link>
            )}
          </Col>
        </Row>
      )}
    </Layout>
  );
};

export default AddProperty;
